// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-routes-region-layout-js": () => import("./../src/routes/region/layout.js" /* webpackChunkName: "component---src-routes-region-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ajouter-un-restaurant-js": () => import("./../src/pages/ajouter-un-restaurant.js" /* webpackChunkName: "component---src-pages-ajouter-un-restaurant-js" */),
  "component---src-pages-carte-js": () => import("./../src/pages/carte.js" /* webpackChunkName: "component---src-pages-carte-js" */),
  "component---src-pages-faq-fr-js": () => import("./../src/pages/faq.fr.js" /* webpackChunkName: "component---src-pages-faq-fr-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-list-fr-js": () => import("./../src/pages/list.fr.js" /* webpackChunkName: "component---src-pages-list-fr-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-outils-js": () => import("./../src/pages/outils.js" /* webpackChunkName: "component---src-pages-outils-js" */),
  "component---src-pages-pack-relance-js": () => import("./../src/pages/pack-relance.js" /* webpackChunkName: "component---src-pages-pack-relance-js" */),
  "component---src-pages-qr-code-menu-js": () => import("./../src/pages/qr-code-menu.js" /* webpackChunkName: "component---src-pages-qr-code-menu-js" */),
  "component---src-pages-site-internet-js": () => import("./../src/pages/site-internet.js" /* webpackChunkName: "component---src-pages-site-internet-js" */)
}

